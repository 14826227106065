import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { apiUrl } from 'config';
import { randomTimeout } from 'utils/randomTimeout';
import { User } from './PageWrapper';

export type LogsPageProps = {
  user: User;
  setLoading: (loading: boolean) => void;
};

export const LogsPage = ({ user, setLoading }: LogsPageProps) => {
  const [logUrls, setLogUrls] = useState<Record<string, Record<string, string>> | null>(null);
  const [standValue, setStandValue] = useState<string>('');
  const [containerValue, setContainerValue] = useState<string>('');
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (logUrls !== null) return;
    setLoading(true);

    randomTimeout(() =>
      axios
        .request<{ dashboardUrls: Record<string, Record<string, string>> }>({
          method: 'GET',
          baseURL: apiUrl,
          url: 'logs',
          headers: { Authorization: `Bearer ${user.accessToken}` }
        })
        .then(async (response) => {
          setLogUrls(response.data.dashboardUrls);

          const standFromQueryString = searchParams.get('stand');
          const standValues = Object.keys(response.data.dashboardUrls);
          const standValue =
            standFromQueryString && standValues.includes(standFromQueryString) ? standFromQueryString : standValues[0];
          setStandValue(standValue);

          const containerFromQueryString = searchParams.get('container');
          const containerValues = Object.keys(response.data.dashboardUrls[standValue]);
          const containerValue =
            containerFromQueryString && containerValues.includes(containerFromQueryString)
              ? containerFromQueryString
              : containerValues[0];
          setContainerValue(containerValue);

          setSearchParams({ stand: standValue, container: containerValue });
        })
        .catch(async (error) => {
          alert(
            error.response
              ? `Request failed with status ${error.response.status}`
              : `Error during a request: ${error.message}`
          );
        })
        .finally(() => setLoading(false))
    );
  }, [user.accessToken, searchParams, logUrls, setLogUrls, setLoading, setStandValue, setSearchParams]);

  return (
    <div className="LogsPage">
      {logUrls !== null && (
        <>
          <iframe
            title={`${containerValue} container logs on ${standValue} stand`}
            src={logUrls[standValue][containerValue] + '?theme=light'}
          />
          <div className="LogsPageSelects">
            <div>
              <label htmlFor="stand-select">Select stand:</label>
              <select
                name="stand"
                id="stand-select"
                value={standValue}
                onChange={(e) => {
                  const nextStandValue = e.target.value;
                  const nextContainerValue = !Object.keys(logUrls[nextStandValue]).includes(containerValue)
                    ? Object.keys(logUrls[nextStandValue])[0]
                    : containerValue;

                  setStandValue(nextStandValue);
                  setContainerValue(nextContainerValue);
                  setSearchParams({ stand: nextStandValue, container: nextContainerValue });
                }}>
                {Object.keys(logUrls).map((standName) => (
                  <option value={standName} key={standName}>
                    {standName}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label htmlFor="container-select">Select container:</label>
              <select
                name="container"
                id="container-select"
                value={containerValue}
                onChange={(e) => {
                  const nextContainerValue = e.target.value;
                  setContainerValue(nextContainerValue);
                  setSearchParams({ stand: standValue, container: nextContainerValue });
                }}>
                {Object.keys(logUrls[standValue]).map((containerName) => (
                  <option value={containerName} key={containerName}>
                    {containerName}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
