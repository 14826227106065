import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { apiUrl } from 'config';
import { randomTimeout } from 'utils/randomTimeout';
import { avg, sum } from 'utils/aggregations';
import { PickByType } from 'utils/pickByType';
import { formatTimeDiff } from 'utils/formatTimeDiff';
import { truncateDecimalPart } from 'utils/truncateDecimalPart';
import { User } from './PageWrapper';

type StandActionLogsDto = {
  standActionLogs: {
    standActionType: string;
    standName: string;
    userName: string;
    createdAt: string;
  }[];
};

type StandActionLog = {
  standActionType: string;
  standName: string;
  userName: string;
  createdAt: Date;
};

export type StandActionLogsPageProps = {
  user: User;
  setLoading: (loading: boolean) => void;
};

type StandReservationInterval = {
  standName: string;
  reservedBy: string;
  reservedAt: Date;
  freedAt: Date | null;
};

export const StandActionLogsPage = ({ user, setLoading }: StandActionLogsPageProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState<number | null>(null);
  const [standActionLogs, setStandActionLogs] = useState<StandActionLog[] | null>(null);
  const pageWeekStartTime = useMemo(() => {
    if (page === null) return null;
    const now = new Date();
    const nowDay = now.getDay();
    const nowWeekStartTime =
      now.getTime() -
      (nowDay == 0 ? 6 : nowDay - 1) * 24 * 60 * 60 * 1000 -
      now.getHours() * 60 * 60 * 1000 -
      now.getMinutes() * 60 * 1000 -
      now.getSeconds() * 1000 -
      now.getMilliseconds();
    const pageWeekStartTime = nowWeekStartTime - page * 7 * 24 * 60 * 60 * 1000;
    return new Date(pageWeekStartTime);
  }, [page]);
  const pageWeekEndTime = useMemo(
    () => (pageWeekStartTime === null ? null : new Date(pageWeekStartTime.getTime() + 7 * 24 * 60 * 60 * 1000)),
    [pageWeekStartTime]
  );
  const standReservationIntervals = useMemo<StandReservationInterval[] | null>(() => {
    if (standActionLogs === null) return null;
    const standReservationIntervals: StandReservationInterval[] = [];

    const standNames = new Set(standActionLogs.map(({ standName }) => standName));
    standNames.forEach((currentStandName) => {
      let reservedAt: Date | null = null;
      let reservedBy: string | null = null;

      standActionLogs
        .filter(({ standName }) => currentStandName === standName)
        .forEach((standActionLog) => {
          if (reservedAt === null && reservedBy === null && standActionLog.standActionType === 'RESERVED') {
            reservedAt = standActionLog.createdAt;
            reservedBy = standActionLog.userName;
          } else if (reservedAt !== null && reservedBy !== null && standActionLog.standActionType === 'FREED') {
            standReservationIntervals.push({
              standName: standActionLog.standName,
              reservedBy,
              reservedAt,
              freedAt: standActionLog.createdAt
            });
            reservedAt = null;
            reservedBy = null;
          }
        });

      if (reservedAt !== null && reservedBy !== null) {
        standReservationIntervals.push({
          standName: currentStandName,
          reservedBy,
          reservedAt,
          freedAt: null
        });
      }
    });

    return standReservationIntervals;
  }, [standActionLogs]);

  const aggregateStandReservationTime = useCallback(
    (groupBy: keyof PickByType<StandReservationInterval, string>, aggregation: (array: number[]) => number) => {
      if (standReservationIntervals === null) return null;

      const groups = Array.from(new Set(standReservationIntervals.map((interval) => interval[groupBy])));

      return groups.reduce<Record<string, number>>(
        (result, currentGroup) => ({
          ...result,
          [currentGroup]: aggregation(
            standReservationIntervals
              .filter((interval) => interval[groupBy] === currentGroup)
              .map(({ reservedAt, freedAt }) => (freedAt ?? new Date())?.getTime() - reservedAt.getTime())
          )
        }),
        {}
      );
    },
    [standReservationIntervals]
  );
  const overalStandReservationTimeGroupedByStandName = useMemo(
    () => aggregateStandReservationTime('standName', sum),
    [aggregateStandReservationTime]
  );
  const averageStandReservationTimeGroupedByStandName = useMemo(
    () => aggregateStandReservationTime('standName', avg),
    [aggregateStandReservationTime]
  );
  const overalStandReservationTimeGroupedByReservedBy = useMemo(
    () => aggregateStandReservationTime('reservedBy', sum),
    [aggregateStandReservationTime]
  );
  const averageStandReservationTimeGroupedByReservedBy = useMemo(
    () => aggregateStandReservationTime('reservedBy', avg),
    [aggregateStandReservationTime]
  );
  const reservedStandsAmountDistribution = useMemo<Map<number, number> | null>(() => {
    if (standActionLogs === null || pageWeekStartTime === null || pageWeekEndTime === null) return null;
    const result: Map<number, number> = new Map<number, number>();
    const currentReservedStands: Set<string> = new Set<string>();
    let previousChangeTime: Date = pageWeekStartTime;

    standActionLogs.forEach(({ standName, createdAt, standActionType }) => {
      if (standActionType === 'RESERVED' && !currentReservedStands.has(standName)) {
        const n = currentReservedStands.size;
        result.set(n, (result.get(n) ?? 0) + createdAt.getTime() - previousChangeTime.getTime());
        previousChangeTime = createdAt;
        currentReservedStands.add(standName);
      }
      if (standActionType === 'FREED' && currentReservedStands.has(standName)) {
        const n = currentReservedStands.size;
        result.set(n, (result.get(n) ?? 0) + createdAt.getTime() - previousChangeTime.getTime());
        previousChangeTime = createdAt;
        currentReservedStands.delete(standName);
      }
    });

    const now = new Date(Math.min(new Date().getTime(), pageWeekEndTime.getTime()));
    if (now.getTime() > previousChangeTime.getTime()) {
      const n = currentReservedStands.size;
      result.set(n, (result.get(n) ?? 0) + now.getTime() - previousChangeTime.getTime());
      previousChangeTime = now;
      currentReservedStands.clear();
    }

    return result;
  }, [standActionLogs, pageWeekStartTime, pageWeekEndTime]);
  const reservedStandsAmountStatistics = useMemo<[number, number] | null>(() => {
    if (reservedStandsAmountDistribution === null) return null;
    const time =
      sum(Array.from(reservedStandsAmountDistribution).map((element) => element[1])) -
      (reservedStandsAmountDistribution.get(0) ?? 0);
    const exp =
      sum(Array.from(reservedStandsAmountDistribution).map(([amount, time]) => amount * time)) / Math.max(time, 1);
    const exp2 =
      sum(Array.from(reservedStandsAmountDistribution).map(([amount, time]) => amount * amount * time)) /
      Math.max(time, 1);
    return [exp, exp2 - exp * exp];
  }, [reservedStandsAmountDistribution]);

  useEffect(() => {
    const pageFromQueryString = parseInt(searchParams.get('page') ?? '');
    if (Number.isNaN(pageFromQueryString) || pageFromQueryString < 0) {
      setSearchParams({ page: '0' });
      return;
    } else if (page !== pageFromQueryString) {
      setPage(pageFromQueryString);
      return;
    }

    setLoading(true);

    randomTimeout(() =>
      axios
        .request<StandActionLogsDto>({
          method: 'GET',
          baseURL: apiUrl,
          url: 'standActionLog',
          headers: { Authorization: `Bearer ${user.accessToken}` },
          params: {
            from: pageWeekStartTime!.getTime(),
            to: pageWeekEndTime!.getTime()
          }
        })
        .then(async (response) => {
          const data = response.data;
          setStandActionLogs(
            data.standActionLogs
              .map((standActionLog) => ({
                standName: standActionLog.standName,
                standActionType: standActionLog.standActionType,
                userName: standActionLog.userName,
                createdAt: new Date(standActionLog.createdAt)
              }))
              .sort(({ createdAt: a }, { createdAt: b }) => a.getTime() - b.getTime())
          );
        })
        .catch(async (error) => {
          alert(
            error.response
              ? `Request failed with status ${error.response.status}`
              : `Error during a request: ${error.message}`
          );
        })
        .finally(() => setLoading(false))
    );
  }, [user.accessToken, searchParams, page, pageWeekEndTime, pageWeekStartTime, setLoading, setSearchParams]);

  return (
    <div className="StandActionLogsPage">
      {page !== null &&
        pageWeekStartTime !== null &&
        pageWeekEndTime !== null &&
        standActionLogs !== null &&
        overalStandReservationTimeGroupedByReservedBy !== null &&
        averageStandReservationTimeGroupedByReservedBy !== null &&
        overalStandReservationTimeGroupedByStandName !== null &&
        averageStandReservationTimeGroupedByStandName !== null &&
        reservedStandsAmountDistribution !== null &&
        reservedStandsAmountStatistics !== null && (
          <>
            <div className="Row">
              <div className="StandActionLogs Cell">
                <div className="StatsBlock">
                  <h3 className="Ellipsis">Overal stand reservation time by user</h3>
                  {Object.entries(overalStandReservationTimeGroupedByReservedBy).map(([reservedBy, time], i) => (
                    <p key={i} className="Ellipsis">
                      <span className="Colored">{reservedBy}</span>: {formatTimeDiff(time)}
                    </p>
                  ))}
                  {Object.entries(overalStandReservationTimeGroupedByReservedBy).length === 0 && <p>Empty</p>}
                </div>
                <div className="StatsBlock">
                  <h3 className="Ellipsis">Average stand reservation time by user</h3>
                  {Object.entries(averageStandReservationTimeGroupedByReservedBy).map(([reservedBy, time], i) => (
                    <p key={i} className="Ellipsis">
                      <span className="Colored">{reservedBy}</span>: {formatTimeDiff(time)}
                    </p>
                  ))}
                  {Object.entries(averageStandReservationTimeGroupedByReservedBy).length === 0 && <p>Empty</p>}
                </div>
                <div className="StatsBlock">
                  <h3 className="Ellipsis">Overal stand reservation time by stand</h3>
                  {Object.entries(overalStandReservationTimeGroupedByStandName).map(([standName, time], i) => (
                    <p key={i} className="Ellipsis">
                      <span className="Colored">{standName}</span>: {formatTimeDiff(time)}
                    </p>
                  ))}
                  {Object.entries(overalStandReservationTimeGroupedByStandName).length === 0 && <p>Empty</p>}
                </div>
                <div className="StatsBlock">
                  <h3 className="Ellipsis">Average stand reservation time by stand</h3>
                  {Object.entries(averageStandReservationTimeGroupedByStandName).map(([standName, time], i) => (
                    <p key={i} className="Ellipsis">
                      <span className="Colored">{standName}</span>: {formatTimeDiff(time)}
                    </p>
                  ))}
                  {Object.entries(averageStandReservationTimeGroupedByStandName).length === 0 && <p>Empty</p>}
                </div>
                <div className="StatsBlock">
                  <h3 className="Ellipsis">Reserved stands amount distribution</h3>
                  {Array.from(reservedStandsAmountDistribution).map(([amount, time], i) => (
                    <p key={i} className="Ellipsis">
                      <span className="Colored">{amount} stands</span>: {formatTimeDiff(time)}
                    </p>
                  ))}
                  <p className="Ellipsis">
                    Expected reserved amount:&nbsp;
                    <span className="Colored">{truncateDecimalPart(reservedStandsAmountStatistics[0])}</span>
                    &nbsp;±&nbsp;
                    <span className="Colored">{truncateDecimalPart(Math.sqrt(reservedStandsAmountStatistics[1]))}</span>
                  </p>
                </div>
              </div>
              <div className="StandActionLogs Cell">
                <h2>Actions</h2>
                {[...standActionLogs].reverse().map((standActionLog, index) => (
                  <div key={index} className="StandActionLog">
                    <p className="Ellipsis">
                      <span className="Colored">{standActionLog.standName}</span> –{' '}
                      {standActionLog.standActionType.toLocaleLowerCase()} by{' '}
                      <span className="Colored">{standActionLog.userName}</span>
                    </p>
                    <p className="Ellipsis">
                      <span className="Colored">{standActionLog.createdAt.toLocaleDateString()}</span> at{' '}
                      <span className="Colored">{standActionLog.createdAt.toLocaleTimeString()}</span>
                    </p>
                  </div>
                ))}
                {standActionLogs.length === 0 && <p>Empty</p>}
              </div>
            </div>
            <div className="Pagination">
              <button onClick={() => setSearchParams({ page: (page + 1).toString() })}>Previous week</button>
              <span>
                {pageWeekStartTime!.toLocaleDateString()} – {pageWeekEndTime!.toLocaleDateString()}
              </span>
              <button disabled={page === 0} onClick={() => setSearchParams({ page: (page - 1).toString() })}>
                Next week
              </button>
            </div>
          </>
        )}
    </div>
  );
};
